import React from 'react'
import c_logo1 from '../img/Companies Logo/logo (1).png'
import c_logo2 from '../img/Companies Logo/logo (2).PNG'
import c_logo3 from '../img/Companies Logo/logo (3).png'
import c_logo4 from '../img/Companies Logo/logo (4).png'
import c_logo5 from '../img/Companies Logo/logo (5).PNG'
import c_logo6 from '../img/Companies Logo/logo (6).PNG'
import c_logo7 from '../img/Companies Logo/logo (7).png'
import tools_icon from '../img/icons/repair-tools.png'

const Home = () => {
  return (
    <>
      <div className="body_container">
        <div className="navbar">
          <span className="logo">Royal Speed Maintenance</span>
          <i class='bx bx-menu'></i>
        </div>
        <div className="welcome_page">
          <div className="companies">
            <img src={c_logo1} alt="" />
            <img src={c_logo2} alt="" />
            <img src={c_logo3} alt="" />
            <img src={c_logo4} alt="" />
            <img src={c_logo5} alt="" />
            <img src={c_logo6} alt="" />
            <img src={c_logo7} alt="" />
          </div>
          <div className="slogans">
            <span className="slogan_title">Customer is always right...</span>
            <div className="map_button">
              <span className='head'>Contact us on</span>
              <a href="tel:31022158" className='call'>
                <i class='bx bxs-phone-call'></i>
                <span>31022158</span>
              </a>
              <a href="tel:55225507" className='call mb'>
                <i class='bx bxs-phone-call'></i>
                <span>55225507</span>
              </a>
              <span className='head'>Whatspp us on</span>
              <a href="https://wa.me/+97431022158" className='call'>
                <i class='bx bxl-whatsapp' ></i>
                <span>31022158</span>
              </a>
              <a href="https://wa.me/+97455225507" className='call mb'>
                <i class='bx bxl-whatsapp' ></i>
                <span>55225507</span>
              </a>
              <span className='head'>Find Our Location</span>
              <a href="https://www.google.com/maps/place/Royal+Speed+Maintenance/@25.2885112,51.5390353,16z/data=!4m5!3m4!1s0x0:0x7fe0862985f409dc!8m2!3d25.2843495!4d51.5389924" className='map_btn'>
                <i class='bx bxs-map'></i>
                <span>Location Map</span>
              </a>
            </div>
          </div>
        </div>
        <div className="service_page">
          <div className="heading">
            <img src={tools_icon} alt="" />
            <span className="head_title">Our Services </span>
          </div>
          <div className="service-box">
            <div className="right">
              <span className="title">Morbike Maintenance</span>
            </div>
            <div className="left">

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home